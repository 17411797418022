import React,{Component} from 'react';
import {Link} from 'react-router-dom';
import ProductRangePDF from "./../../images/brochure/Almol-Range.pdf";

class HeaderMenu extends Component{
	render(){
		return(
			<>
				<ul className="nav navbar-nav">
					<li><Link to={'/'}><span className="ti-home primary"></span></Link></li>
					<li><Link to={'#'}>Company<i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'/history'} className="dez-page">History</Link></li>
							{/* <li><Link to={'#'} className="dez-page">Team</Link></li> */}
							<li><Link to={'/mission'} className="dez-page">Mission</Link></li>
						</ul>
					</li>
					<li><Link to={'#'}>Products<i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'/natural-casings'} className="dez-page">Natural Casings</Link></li>
							<li><Link to={'/hukki-casings'} className="dez-page">Hukki Casings</Link></li>
							<li><Link to={'/worldpac-sun-casings'} className="dez-page">World Pac / Sun Casings</Link></li>
							<li><Link to={'/offal-byproduct'} className="dez-page">Offal &amp; By-products</Link></li>
						</ul>
					</li>
					<li><Link to={'/equipment'}>Equipment<i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'/equipment-centrifuge'} className="dez-page">Centrifuges</Link></li>
							<li><Link to={'/equipment-auto-casing'} className="dez-page">Automatic Casing Lines</Link></li>
							<li><Link to={'/equipment-hog-bung'} className="dez-page">Hog Bung Machines</Link></li>
							<li><Link to={'/equipment-conveyor'} className="dez-page">Cannons &amp; Conveyors</Link></li>
							<li><Link to={'/equipment-compactor'} className="dez-page">Compactors</Link></li>
							<li><Link to={'/equipment-cleaning-screen'} className="dez-page">Cleaning Screens</Link></li>
							<li><Link to={'/equipment-other'} className="dez-page">Other</Link></li>
						</ul>
					</li>
					<li><Link to={'#'}>Downloads<i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							{/* <li><Link to={ProductRangePDF} className="dez-page">Brochure</Link></li> */}
							<li><a href={ProductRangePDF}>Brochure</a></li>
							{/* <li><Link to={'#'} className="dez-page">Equipment Brochure</Link></li> */}
						</ul>
					</li>
					<li><Link to={'#'}>Media<i className="fa fa-chevron-down"></i></Link>
						<ul className="sub-menu">
							<li><Link to={'/blog'} download className="dez-page">News</Link></li>
							{/* <li><Link to={'#'} className="dez-page">Media Releases</Link></li>
							<li><Link to={'#'} className="dez-page">News</Link></li> */}
						</ul>
					</li>
					<li><Link to={'/contact'}>Contact Us</Link></li>
				</ul>
			</>
		)
	}
}
export default HeaderMenu;