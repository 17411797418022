import React, { Component } from 'react';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import beefPic from './../../images/casings/beef.jpg';
import sheepPic from './../../images/casings/sheep.jpg';
import hogPic from './../../images/casings/hog.jpg';
import brochureIcon from './../../images/brochure/almol-product-overview.jpg';
import ProductRangePDF from "./../../images/brochure/Almol-Range.pdf";

import bnr from './../../images/banner/bnr2-7.jpg';
import bgr from './../../images/background/bcr-2.jpg';


class CasingsNatural extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Natural Casings' midmenu="Products" activeMenu='Natural Casings' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bgr + ")" }}>
                            <div className="container background-white p-t10 p-b10">
                                <div className="row border-almol">
                                    <div className="col-lg-6 col-md-8 p-10 m-27">
                                        <div className="col-lg-10">
                                            <p><b>Sheep Casings</b></p>
                                            
                                            <p>Sheep Casings are the highest quality small diameter casings used for the finest in sausages such as: Bratwurst, Frankfurters and Pork Sausage.</p>
                                            
                                            <p>These casings combine tenderness with sufficient strength to withstand the filling, cooking and smoking operations.</p>
                                            
                                            <p>Color varies according to country of origin; color ranges from white to gray, but this variation does not indicate quality, strength, capability of smoke penetration, etc.</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4 about-img p-50 p-t50 m-27 ">
                                        <img src={sheepPic} data-tilt alt="" />
                                    </div>
                            </div>
                            <hr />
                            <div className="row border-almol">
                                <div className="col-lg-6 col-md-8 p-10 m-27">
                                    <div className="col-lg-10">
                                        <p><b>Hog Casings</b></p>
                                        
                                        <p>Hog Casings are used for Cooked Sausage. Country Style Sausage, Fresh Pork Sausage, Pepperoni, Italian Sausage, large Frankfurters, Kishka, Kielbasa and Bratwurst...to name just some of the best-selling items.</p>

                                        <p>The Almol difference is in the detail. Almol specifies Hog Casings are pre-flush cutting only where there are holes to separating shorts, medium &amp; long lengths before selection of calibration.</p>
                                        
                                        <p>Our aim is to make a strict selected casing that has the longest possible strands. What we also understand better than others is it is not only with minimum length but how many long shorts are in each bundle that can cause big problems with sausage makes. Following is maximum strands per 95-100 yards knot &amp; strand spread details</p>

                                        <p>Maximum Number of strands 11-13</p>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 about-img p-t50 m-27">
                                    <img src={hogPic} data-tilt alt="" />
                                </div>
                            </div>
                            <hr />
                            <div className="row border-almol">
                                <div className="col-lg-6 col-md-8 p-10 m-27">
                                    <div className="left row m-lr0 ">
                                        <div className="col-lg-10">
                                            <p><b>Beef Casings</b></p>
                                            
                                            <p>"Beef Rounds" - these casings derive their name from their characteristic "ring" or "round" shape. Beef Rounds are used for Ring Bologna, Ring Liver Sausage, Mettwurst, Polish Sausage, Blood Sausage, Kishka and Holsteiner. Stuffing capacities indicated are approximate "Green Weights." Beef Rounds are measured into sets or bundles of 9, 18 and 30 meters.</p>

                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-4 about-img p-50 p-t50 m-27">
                                    <img src={beefPic} data-tilt alt="" />
                                </div>
                            </div>
                            <div className="p-t50"><hr /></div>
                            <div className="p-b50"><hr /></div>
                            </div>
                            <div className="section-full add-to-call bg-primary p-tb30">
                                <div className="container">
                                    <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                        <h2 className="m-b10 m-t10 text-white">Download Our Latest Brochure</h2>
                                        <div><a href={ProductRangePDF} download><img src={brochureIcon} alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default CasingsNatural;