import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import haccpBadge from '../../images/haccp.png';

class Footer extends Component {
    render() {
        return (
            <>
                <footer className="site-footer footer-white" >
                    <div className="footer-top">
                        <div className="container">
                            <div className="row">
                                <div className="col-5 col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_services border-0">
                                        <h5 className="m-b30 text-white">Quick Links</h5>
                                        <ul>
                                            <li><Link to={"/mission"}>About Almol</Link></li>
                                            <li><Link to={"/natural-casings"}>Natural Casings</Link></li>
                                            <li><Link to={"/equipment"}>Equipment</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4">
                                    <div className="widget widget_getintuch">
                                        <h5 className="m-b30 text-white">Contact us</h5>
                                        <ul>
                                            <li><i className="ti-location-pin"></i><strong>Address: </strong>139 Beauchamp Rd, Matraville NSW 2036</li>
                                            <li><i className="ti-mobile"></i><strong>Phone: </strong>+61 2 9666 6755</li>
                                            <li><i className="ti-email"></i><strong>Email: </strong>sales@almol.com</li>
                                            {/* <li><i className="ti-email"></i><button>CONTACT FORM</button></li> */}
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-4 ">
                                    <div className="widget">
                                        <div className="image-container">
                                            <img width="50%" src={haccpBadge} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="footer-bottom bg-primary">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-6 text-left "> 
                                <span>Copyright © 2021 Almol Australiasia Casings p/l</span> </div>
                                <div className="col-lg-6 col-md-6 col-sm-6 text-right ">
                                    <div className="widget-link ">
                                        <small className="aqis">AQIS export license no. 3532</small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
				
            </>
        );
    }
}

export default Footer;