import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import B800 from './../../images/equipment/auto-casing/B800.jpg';
import LSO600 from './../../images/equipment/auto-casing/LSO600.jpg';
import LB800 from './../../images/equipment/auto-casing/LB800.jpg';
import LAS30 from './../../images/equipment/auto-casing/LAS30_35.jpg';
import LT2008 from './../../images/equipment/auto-casing/LT2008.jpg';
import FSO500M from './../../images/equipment/auto-casing/FSO500M.jpg';
import MOD6A from './../../images/equipment/auto-casing/MOD6A.jpg';
import SO600 from './../../images/equipment/auto-casing/SO600.jpg';

import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';


class EquipmentAutoCasing extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Automatic Casing Lines' midmenu="Equipment" activeMenu='Automatic Casing Lines' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                    <p><b>Automatic chopping machine</b></p> 
                                    <p><i>Model 6/A</i></p>

                                    <p>Made of AISI 304 stainless steel. </p>
                                    
                                    <p>It is applied to the finishing machine on the L.S.O. lines for processing pig and sheep runners.</p> 
                                    
                                    <p>The machine automatically carries out the phases of feeding, chopping and expelling the nerve in a pulp, making the product suitable to be conveyed with normal pumps. </p>
                                    
                                    <p>The machine is supplied complete with low-voltage electrical control panel, to CEI standards, with motor protection</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={MOD6A} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                    <p><b>Automatic Line</b></p>
                                    <p><i>Model L.B.800/3/4</i></p> 

                                    <p>Suitable for cleaning beef rounds (small intestines). </p>
                                    
                                    <p>Made of AISI 304 stainless steel. This line is composed of tanks and various machines for separating and collecting rounds for each different phase of processing.</p>

                                    <p>The rounds move through the line automatically. The rounds are collected on the front receiving tray where they are placed on a moving chain conveyor. </p>
                                    
                                    <p>The rounds pass through the first machine, which strips the manure and defats the rounds and then fall onto the next moving conveyor. Here, the rounds are dragged through hot water and are conveyed into the second and/or third stripping and defating machines. </p>
                                    
                                    <p>The mucosa is collected in troughs and directed to the side of the machine for easy disposal. After this process, the rounds are collected in a turning tank where the worker will manually invert the rounds inside out. The operator then feeds the rounds into the last machine for the internal de-sliming phase. </p>
                                    
                                    <p>The machine is supplied with an electric control panel with a stainless steel cabinet, overloads and low tension 24 V control push-buttons.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={LB800} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Automatic Line</b></p>
                                        <p><i>Model L.S.O. 600/3/4 &amp; L.S.O.800-1000/3/4/5</i></p>

                                        <p>Suitable for cleaning hog and sheep intestines. </p>
                                        
                                        <p>Made of AISI 304 stainless steel. </p>
                                        
                                        <p>It is composed of working units, in which during the working, also processes the diversified disposal waste according to their composition automatically. </p>
                                        
                                        <p>The first working unit empties the intestine that is to be placed on the automatic feed belt immediately after extracting the intestinal pack. </p>
                                        
                                        <p>It automatically passes on to the second unit for the following processing phase and will then stop at the outfeed. </p>
                                        
                                        <p>For the final phase the operator should pick up a few intestines and put them into the finishing machine from which they will come out processed. </p>
                                        
                                        <p>The machine is supplied complete with an electric control panel with a stainless steel cabinet, overload cutout and low tension 24 V control push-buttons. </p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={LSO600} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Stomach Strip Cutter</b></p>
                                        <p><i>Model LT 2008</i></p>

                                        <p>Suitable to cut beef and sheep stomachs in strips. </p>
                                        
                                        <p>The machine is completely made in AISI 304 stainless steel and it is supplied with a control panel with a stainless steel cabinet. </p>
                                        
                                        <p>The working cycle consists of manual loading, slicing into 6mm width strips with variable-lengths and automatic discharging of the animal byproduct.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={LT2008} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Stripping - De-Sliming - De-Greasing Machine</b></p>
                                        <p><i>Model B800</i></p>

                                        <p>Suited for stripping, de-greasing and de-sliming beef rounds and large intestines (middles). </p>
                                        
                                        <p>It is made entirely of AISI 304 stainless steel. </p>
                                        
                                        <p>The process begins by manually inserting 3/4 intestines for the first phase of stripping and de-greasing. For optimal quality, it is recommended to reinsert the intestines for a second time for the first phase of stripping and de-greasing. Then the operator proceeds with the next phase of turning the intestines inside-out and re-inserting them for the de-sliming phase. </p>
                                        
                                        <p>The machine is supplied complete with an electric control panel with a stainless steel cabinet, overload cutout and low tension 24 V control push-buttons.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={B800} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Automatic Processing Line for Pig Stomachs </b></p> 
                                        <p><i>Models L.A.S.30-35</i></p>
                                        
                                        <p>The automatic line is made up of the following:</p>
                                            
                                        <p>No. 01 Stomach Splitter Machine Mod. M.A.S. is designed to split and prewash pig stomachs (complete with a hopper for recovery of
                                        waste material) which automatically fall into the centrifuge bin.</p>
                                        
                                        <p>No. 01 Centrifuge Mod. S30/35 are designed for washing, degreasing and desliming the stomachs; complete with a lifter to feed the
                                        centrifuge.</p>
                                        
                                        <p>Control panel containing electrical and compressed air systems.</p>
                                        
                                        <p>A single operator is necessary to feed the Stomach Splitter Mod. M.A.S.. </p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={LAS30} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Finishing Machine</b></p> 
                                        <p><i>Model F.S.O.500M / F.S.O.800</i></p> 

                                        <p>This machine is suitable for finishing pig and sheep runners (small intestines) after the runners have gone through one or more stripping-crushing machines. </p>
                                        
                                        <p>It is made entirely of AISI 304 stainless steel.</p>

                                        <p>The rollers are equipped with a special adjustment system to process the runners perfectly. </p>
                                        
                                        <p>The machine is supplied completely with an electric control panel with a stainless steel cabinet, overloads and low tension 24 V control push-buttons, or with a start/stop control push-button strip. </p>
                                        
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={FSO500M} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Wringing / Crushing Machine</b></p> 
                                        <p><i>Model S.O. 600 / 800 / 1000</i></p> 

                                        <p>To couple with the finishing machine, suitable to prepare the casing for the following finishing phase. </p>
                                        <p>Made of AISI 304 stainless steel. </p>
                                        <p>The rollers are equipped with a special adjustment system to process the casing perfectly. </p>
                                        <p>The machine is supplied complete with an electric control panel with a stainless steel cabinet, overload cutout and low tension 24 V control push-buttons. </p>
                                        
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={SO600} alt="" />
                                    </div>
                                </div>
                                
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentAutoCasing;