import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

/*  Equipment Images  */
import conveyor from './../../images/equipment/cannon/conveyor.jpg';

//Images
import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';


class EquipmentConveyor extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Cannons &amp; Conveyors' midmenu="Equipment" activeMenu='Cannnons &amp; Conveyors' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Pneumatic Conveyors</b></p>

                                        <p>The pneumatic conveyors are employed to transport solid and liquid products inside chemical and food industries and along the slaughtering lines by means of pipings. A pneumatic conveying system offers many advantages:</p>

                                        <ul className="equip-list">
                                            <li>Possibility of crossing any kind of plant</li>
                                            <li>Speedy transportation</li>
                                            <li>Extremely reduced transport costs</li>
                                            <li>Low maintenance costs</li>
                                            <li>Construction in compliance with the latest hygienic and sanitary rules in force</li>
                                            <li>Construction in compliance with the directive 97/23 CE (P.E.D.)</li>
                                        </ul>

                                        <p>The pneumatic conveyors are completely built in AISI 304 stainless steel.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={conveyor} alt="" />
                                    </div>
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentConveyor;