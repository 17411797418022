import React, { Component } from 'react';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import about9 from './../../images/about/pic92.jpg';
import bg3 from './../../images/background/bcr-2.jpg'; 
import bnr from './../../images/banner/bnr2-7.jpg';

class AboutHistory extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Company Mission' midmenu="Company" activeMenu='Mission' />
					</div>	
                    <div className="content-block">
                        <div className="section-full industry-service" style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container background-white">
                                <div className="row">
                                    <div className="col-lg-5 col-md-2 about-img p-t100">
                                        <div className="image-container">
                                            <img src={about9} data-tilt className="align-center" alt="" />
                                        </div>
                                    </div>
                                    <div className="col-lg-7 col-md-10">
                                        <div className="abuot-box left row m-lr0 border-almol">
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">Almol<span className="bg-primary"></span></h2>
                                                <p className="text-gray-dark"><i>Est. 1969</i></p>
                                                <h6 className="text-gray-dark font-weight-300"><i>We operate to procure and produce the highest quality sausage casings</i></h6>
                                                <h6 className="text-gray-dark font-weight-700"><i>and provide them with the ultimate level of customer service and satisfaction.</i></h6>
                                            </div>
                                            <div className="col-lg-8">
                                                <p><b>How We Operate</b></p>
                                               
                                                <p>We strive to achieve the best possible result in every aspect of our business.  Our processing equipment, quality control procedures, IT systems, attention to detail and prompt, efficient resolution of problems along with our team of highly trained, dedicated staff ensure that our client’s needs are satisfied. </p> 

                                                <p>ALMOL operates under a strict Approved Export Standard Operating Procedures developed &amp; monitored by AQIS in accordance with export regulations.  These procedures cover all aspects of the receipt, handling, storage, distribution, product integrity, health, hygiene and hazard analysis critical control points (‘HACCP’).</p>

                                                <p><b>At Almol, casings and related products are our livelihood.</b> </p> 

                                                <p>Through experience, dedication and a commitment to excellence, we are able to provide our clients the highest quality casings and related products/services at competitive prices.</p>

                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default AboutHistory;