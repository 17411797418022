import React, { Component } from 'react';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import hukkiTrad from './../../images/product/hukki/hukki-trad.jpg';
import hukkiNet from './../../images/product/hukki/hukki-net-sewn.jpg';
import hukkiFibNet from './../../images/product/hukki/hukki-fib-net-clean.jpg';
import hukkiHitch from './../../images/product/hukki/hukki-hitch.jpg';
import hukkiHoneycomb from './../../images/product/hukki/hukki-honeycomb.jpg';
import hukkiHoneycombL from './../../images/product/hukki/hukki-honeycomb-l.jpg';


import hukkiLogo from './../../images/casings/hukki-logo.jpg';
import ProductRangePDF from "./../../images/brochure/Almol-Range.pdf";
import brochureIcon from './../../images/brochure/almol-product-overview.jpg';
import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';

class CasingsNatural extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					    <div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Hukki Casings' midmenu="Products" activeMenu='Hukki Casings' />
                    </div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="box-title m-tb0"><span className="bg-primary"></span></h2>

                                </div>
                            </div>
                            <div className="container">
                                <div className="row background-white">
                                    <div className=" col-lg-8">
                                        <p><b>Hukki Casings</b></p>
                                        
                                        <p>Almol Casings are the official Australian distributer of the full range of Hukki Casings.</p>

                                        <p>HUKKI's are used in the production of dry sausage like smoked, airdried or salami with mould. The combination of textile tube and fine collagen film produces high strength and good elasticity. The permeable sausage casing cuts down the drying time. Watervapour and smoke permeate easily.</p>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="hukki-logo-container">
                                            <img src={hukkiLogo} data-tilt className="align-center" alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                             <div className="container p-t100">
                                <div className="section-head text-black text-center background-white">
                                    <h2 className="text-gray-dark m-b10">The HUKKI Range</h2>
                                    <h2 className="box-title m-tb0"><span className="bg-primary"></span></h2>

                                </div>
                            </div>
                            <div className="container p-t50 background-white border-almol">
                                <div className="row">
                                        <div className=" col-lg-4">
                                            <p><b>Hukki</b></p>
                                            
                                            <p>
                                                <b>Diameter :</b>
                                                <ul className="hukkiList">
                                                    <li>40mm to 200mm</li>
                                                </ul>
                                            </p>

                                            <b>Customizing :</b>
                                            <ul className="hukkiList">
                                                <li>Bundles</li>
                                                <li>Reels</li>
                                                <li>Shirred</li>
                                            </ul>
                                           

                                            <b>Customizing in cut pieces :</b>
                                            <ul className="hukkiList">
                                                <li>One end sewn</li>
                                                <li>Tied and looped</li>
                                                <li>Sewn to shape</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-8 col-md-4">
                                            <img src={hukkiTrad} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container background-white border-almol p-t50">
                                <div className=" row">
                                        <div className=" col-lg-4">
                                            <p><b>Hukki NET</b></p>
                                            
                                            <p>
                                                <b>Diameter :</b>
                                                <ul className="hukkiList">
                                                    <li>40mm to 145mm</li>
                                                </ul>
                                            </p>

                                            <b>Customizing :</b>
                                            <ul className="hukkiList">
                                                <li>Bundles</li>
                                                <li>Reels</li>
                                                <li>Shirred</li>
                                            </ul>
                                           

                                            <b>Customizing in cut pieces :</b>
                                            <ul className="hukkiList">
                                                <li>One end sewn</li>
                                                <li>Tied and looped</li>
                                                <li>Sewn to shape</li>
                                            </ul>
                                        </div>
                                        <div className="col-lg-8 col-md-4">
                                            <img src={hukkiNet} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container background-white border-almol p-t50">
                                <div className=" row">
                                    <div className=" col-lg-4">
                                        <p><b>Hukki Hitch & Gitternet</b></p>
                                        
                                        <p>
                                            <b>Diameter :</b>
                                            <ul className="hukkiList">
                                                <li>40mm to 175mm</li>
                                            </ul>
                                        </p>

                                        <b>Customizing :</b>
                                        <ul className="hukkiList">
                                            <li>Bundles</li>
                                            <li>Shirred</li>
                                        </ul>
                                        

                                        <b>Customizing in cut pieces :</b>
                                        <ul className="hukkiList">
                                            <li>Tied and looped</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-8 col-md-4">
                                        <img src={hukkiHitch} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container background-white border-almol p-t50">
                                <div className=" row">
                                    <div className=" col-lg-4">
                                        <p><b>Hukki Honeycomb</b></p>
                                        
                                        <p><b>7, 8, 9, or 12 Meshes</b></p>

                                        <p>
                                            <b>Diameter :</b>
                                            <ul className="hukkiList">
                                                <li>65mm to 175mm</li>
                                            </ul>
                                        </p>

                                        <b>Customizing :</b>
                                        <ul className="hukkiList">
                                            <li>Bundles</li>
                                            <li>Shirred</li>
                                        </ul>
                                        

                                        <b>Customizing in cut pieces :</b>
                                        <ul className="hukkiList">
                                            <li>Tied and looped</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-8 col-md-4">
                                        <img src={hukkiHoneycomb} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container background-white border-almol p-t50">
                                <div className=" row">
                                    <div className=" col-lg-4">
                                        <p><b>Hukki Large Honeycomb</b></p>
                                        
                                        <p><b>3, 4, or 6 Meshes</b></p>

                                        <p>
                                            <b>Diameter :</b>
                                            <ul className="hukkiList">
                                                <li>80mm to 200mm</li>
                                            </ul>
                                        </p>

                                        <b>Customizing :</b>
                                        <ul className="hukkiList">
                                            <li>Bundles</li>
                                            <li>Shirred</li>
                                        </ul>
                                        

                                        <b>Customizing in cut pieces :</b>
                                        <ul className="hukkiList">
                                            <li>Tied and looped</li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-8 col-md-4">
                                        <img src={hukkiHoneycombL} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container background-white border-almol p-t50">
                                <div className=" row">
                                    <div className=" col-lg-4">
                                        <p><b>Hukki Netted Fibrous</b></p>
                                        
                                        <p>
                                            Suitable for smoked or dried products, fibrous permits smoke penetration and moisture evaporation, facilitating fast efficient production.
                                        </p>

                                        <p>
                                            <b>Diameter :</b>
                                            <ul className="hukkiList">
                                                <li>50mm to 191mm</li>
                                            </ul>
                                        </p>

                                        <b>Customizing :</b>
                                        <ul className="hukkiList">
                                            <li>Bundles</li>
                                            <li>Shirred</li>
                                            <li>Tied and looped</li>
                                            <li>Range of net / casing colours</li>
                                            <li><i>See brochure for more</i></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-8 col-md-4">
                                        <img src={hukkiFibNet} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                            <br />
                            <div className="container background-white border-almol p-t50">
                                <div className=" row">
                                    <div className=" col-lg-4">
                                        <p><b>Hukki Multilayer</b></p>
                                        
                                        <p>
                                            High barrier to moisture and oygen maximizes yields and protects flavour.
                                        </p>

                                        <p>
                                            <b>Diameter :</b>
                                            <ul className="hukkiList">
                                                <li>50mm to 191mm</li>
                                            </ul>
                                        </p>

                                        <b>Customizing :</b>
                                        <ul className="hukkiList">
                                            <li>Bundles</li>
                                            <li>Shirred</li>
                                            <li>Tied and looped</li>
                                            <li>Range of net / casing colours</li>
                                            <li><i>See brochure for more</i></li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-8 col-md-4">
                                        <img src={hukkiTrad} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                            <div className="p-b100 p-t100"><br /></div>
                            <div className="section-full add-to-call bg-primary p-tb30">
                                <div className="container">
                                    <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                        <h2 className="m-b10 m-t10 text-white">Download Our Latest Brochure</h2>
                                        <div><a href={ProductRangePDF} download><img src={brochureIcon} alt="" /></a></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default CasingsNatural;