import React, { Component } from 'react';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import about9 from './../../images/about/pic9.jpg';
import bg3 from './../../images/background/bcr-2.jpg';
import bnr from './../../images/banner/bnr2-7.jpg';

class AboutHistory extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Company History' midmenu="Company" activeMenu='History' />
					</div>	
                    <div className="content-block">
                        <div className="section-full content-inner-2" style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            
                            <div className="container">
                                <div className="row ">
                                    <div className="col-lg-5 col-md-4 about-img p-t100">
                                        <img src={about9} data-tilt alt="" />
                                    </div>
                                    <div className="col-lg-7 col-md-8">
                                        <div className="about-box left row m-lr0 border-almol">
                                            <div className="col-lg-4">
                                                <h2 className="box-title m-tb0">Almol<span className="bg-primary"></span></h2>
                                                <p className="text-gray-dark"><i>Est. 1969</i></p>
                                                <h6 className="text-gray-dark font-weight-300"><i>Our name originates from a newlywed dream of starting a company together. </i></h6>
                                                <h6 className="text-gray-dark font-weight-700"><i>Al (Alf) and Mol (Molly) became Almol</i></h6>
                                            </div>
                                            <div className="col-lg-8">
                                                <p><b>Who Are We?</b></p>
                                                <p>Almol is a 100% Australian owned and run company, and is one of Australia’s leading producers and suppliers specialising in natural sausage casings.</p>  

                                                <p>Established in 1969 with its head office in Botany NSW, Almol has grown to become a market leader in the field of casings and a well-respected member of the meat industry.</p>  

                                                <p>Our current site, a state of the art facility opened in Matraville in 2011, allowed us to combine our focus on customer service and strict standards of quality & safety with divisons driving innovation, technology, and product design.</p>

                                                <p>We have a number of affiliated sister companies and distributors right around Australia, and the world. </p>
                                                
                                                <h6>Together we work to provide the best possible casings and related product for our clients.</h6>
                                                
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default AboutHistory;