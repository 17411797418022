import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import vga1200 from './../../images/equipment/hog-bung/VGA-1200.jpg';
import hbi8a from './../../images/equipment/hog-bung/8A.jpg';

import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';


class EquipmentHogBung extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Hog Bung Machines' midmenu="Equipment"  activeMenu='Hog Bung Machines' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Automatic Hog Bung Flushing Machine</b></p>
                                        <p><i>Model VGA-1200</i></p>

                                        <p>The Hog Bung Flushing Machine is specially designed to automatically flush the manure and cut off the crown.</p><p>It is an ideal machine for reducing labour and water consumption.</p>

                                        <p>The machine is made entirely of AISI 304 stainless steel and it is supplied with:</p>

                                        <ul className="equip-list">
                                            <li>Suitable catenary to support the bungs.</li>
                                            <li>The speed of the catenary is adjustable by inverter.</li>
                                            <li>Water distributing system to the bungs emptying.</li>
                                            <li>Automatic cutting of the worked bungs.</li>
                                            <li>Automatic Removing System for the reject.</li>
                                            <li>Hot water self-washing system.</li>
                                        </ul>
                                        
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={vga1200} data-tilt alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Hog Bung Inverter</b></p>
                                        <p><i>Model 8-A & 8-A2</i></p>

                                        <p>The hog bung (rectum) inverter is made from an AISI 304 stainless steel framework, on which a cylindrical tank is mounted on. Inside the tank, a suction pump creates a drop in pressure. When the bung is inserted on the reversal duct, it gets sucked inside the tank, and, at the same time, it gets inverted. The tank reclines to allow for easy unloading and is equipped with a manual or pneumatic door. The machine is equipped with an electric panel with low tension 24 V push-buttons.</p>

                                       
                                        
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={hbi8a} data-tilt alt="" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentHogBung;