import React, { Component } from 'react';
import {Link} from 'react-router-dom';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

import bnr from './../../images/banner/bnr2-4.jpg';
import equip from './../../images/product/equipment/equip.jpg';
import brochureIcon from './../../images/brochure/almol-product-overview.jpg';
import bg3 from './../../images/background/bg3.png';

class Equipment extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Almol Equipment' activeMenu='Almol Equipment' />
					</div>
                    <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                        <div className="section-full content-inner-2">
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h2 className="text-gray-dark m-b10">Almol Equipment</h2>
                                    <h2 className="box-title m-tb0"><span className="bg-primary"></span></h2>

                                </div>
                            </div>
                            <div className="container">
                                    <div className="row">
                                            <div className="col-lg-12 text-center">
                                                <p>We specialize in the consulting, designing and manufacturing of machines for processing animal by-products and complete lines
                                                for intestine operations for beef, pig and sheep processing plants. Our unique machines manufactured in Europe are designed to
                                                satisfy medium and large productions in compliance with the strictest regulations on safety, quality, noise and hygiene.</p>
                                            </div>
                                            
                                    </div>
                                </div>
                                <div className="container p-t100">
                                    <div className="section-head text-black text-center">
                                        <h4 className="text-gray-dark m-b10">We have supplied meat processing equipment to plants owned by the following clients:</h4>
                                        <h2 className="box-title m-tb0"><span className="bg-primary"></span></h2>

                                    </div>
                                </div>
                            </div>
                            <div className="container p-t50 p-b100">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <ul className="equip-list">
                                            <li>H.W Greenham and Sons</li>
                                            <li>Prime Meat</li>
                                            <li>Loddon County Lamb</li>
                                            <li>Cargill Meat Processors</li>
                                            <li>Midfield Meat International</li>
                                            <li>Swift Australia</li>
                                            <li>Jarvis A.N.Z.</li>
                                            <li>Jarvis Equipment</li>
                                            <li>Harvey Industries Group</li>
                                            <li>Almol Casings</li>
                                            <li>Primo Scone Abattoir</li>
                                            <li>Cargill Beef Australia</li>
                                            <li>KR Darling Downs</li>
                                            <li>Alliance New Zealand</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={equip} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <div className="section-full add-to-call bg-primary p-tb30">
                                <div className="container">
                                    <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                        <h2 className="m-b10 m-t10 text-white">Download Our Latest Brochure</h2>
                                        <div><Link to={"#"} className="site-button button-md white"><img src={brochureIcon} alt="" /></Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}

export default Equipment;