import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import P10 from './../../images/equipment/centrifuge/P10.jpg';
import P30 from './../../images/equipment/centrifuge/P30.jpg';
import D40 from './../../images/equipment/centrifuge/D40.jpg';
import LC10 from './../../images/equipment/centrifuge/LC10.jpg';
import R10 from './../../images/equipment/centrifuge/R10.jpg';
import R30 from './../../images/equipment/centrifuge/R30.jpg';
import TL30 from './../../images/equipment/centrifuge/TL30.jpg';
import S30 from './../../images/equipment/centrifuge/S30.jpg';

import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';


class EquipmentCentrifuge extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Centrifuges' midmenu="Equipment" activeMenu='Centrifuges' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                    <p><b>Cleaning Centrifuges</b></p>
                                    <p><i>Model P10, P15, P20, P25</i></p>
                                    
                                    <p>These machines are suitable for washing and peeling (whitening) the internal part of the beef omasums and the beef and sheep tripes. </p>
                                    
                                    <p>The centrifuges are made entirely of AISI304 stainless steel and are equipped with a pneumatic opening system for internal cleaning. </p>
                                    
                                    <p>They are supplied with an air-operated product outfeed door and an electric control panel with a stainless steel cabinet. </p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={P10} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                    <p><b>Cleaning Centrifuges</b></p>
                                    <p><i>Model P30, P35</i></p>
                                    
                                    <p>These machines are suitable for washing and peeling (whitening) the internal part of the beef omasums and the beef and sheep tripes. </p>
                                    
                                    <p>The centrifuges are made entirely of AISI304 stainless steel and are equipped with a pneumatic opening system for internal cleaning. </p>
                                    
                                    <p>It is supplied with an electric control panel with a stainless steel cabinet. The processing cycle is completely automatic.</p>
                                    
                                    <p>The animal byproducts are loaded in the top of the machine by a lifter. 
                                    </p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={P30} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        
                                        <p><b>Automatic Centrifuge - Cut and Wash</b></p>
                                        <p><i>Model TL30, TL35</i></p>

                                        <p>The automatic centrifuge - cut and wash is a machine suitable to cut and wash large instestines of beef, pig, sheep and horse. </p>
                                        
                                        <p>It is made entirely of AISI 304 stainless steel. </p>
                                        
                                        <p>It is supplied with an electric control panel with a stainless steel cabinet. The processing cycle is completely automatic. </p>
                                        
                                        <p>The intestines are loaded in the top of the machine by a lifter. Inside the machine, special knives cut and wash the intestines simultaneously, with cold (for the processing) and hot (for the self-washing) water. </p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={TL30} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        
                                        <p><b>Automatic Centrifuge for Pig Heart Washing</b></p>
                                        <p><i>Model LC10</i></p>

                                        <p>The centrifuge is made entirely of AISI 304 stainless steel and is equipped with a pneumatic opening system for internal cleaning. </p>
                                        
                                        <p>It is supplied with an electric control panel with a stainless steel cabinet. </p>
                                        
                                        <p>The process cycle includes charging, washing, drying and discharging the animal byproduct. </p>
                                        
                                        <p>After the introduction of the byproduct in the charging hopper, the process can be made semiautomatic (the machine stops after each cycle) or automatic (the cycle starts again after the closing of the discharging door and its length can be controlled by timers). </p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={LC10} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        
                                        <p><b>Refining Centrifuge</b></p>
                                        <p><i>Model R10, R15, R20, R25</i></p>

                                        <p>These machines are suitable for removing the fat – refining beef and sheep tripes and beef omasums, after having received a first treatment with the O.C. di Ollari &amp; Conti cleaning centrifuge. </p>
                                        
                                        <p>If necessary, they are suitable to de-hair pig and beef ears. </p>
                                        
                                        <p>The centrifuges are made entirely of AISI304 stainless steel and are equipped with a pneumatic opening system for internal cleaning. </p>
                                        
                                        <p>They are supplied with an air-operated product outfeed door and an electric control panel with a stainless steel cabinet.</p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={R10} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        
                                        <p><b>Refining Centrifuge</b></p>
                                        <p><i>Model R30, R35</i></p>

                                        <p>These machines are suitable for removing the fat – refining beef and sheep tripes and beef omasums, after having received a first treatment with the O.C. di Ollari &amp; Conti cleaning centrifuge. </p>
                                        
                                        <p>If necessary, they are suitable to de-hair pig and beef ears. </p>
                                        
                                        <p>The centrifuges are made entirely of AISI304 stainless steel and are equipped with a pneumatic opening system for internal cleaning. </p>
                                        
                                        <p>It is supplied with an electric control panel with a stainless steel cabinet. The processing cycle is completely automatic. </p>
                                            
                                        <p>The animal byproducts are loaded in the top fo the machine by a lifter.</p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={R30} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        
                                        <p><b>Degreasing Centrifuge</b></p>
                                        <p><i>Model S30, S35</i></p>

                                        <p>Recommended to wash, degrease and de-slime pig stomachs and beef and pig large intestines. </p>
                                        
                                        <p>The centrifuges are made entirely of AISI304 stainless steel and are equipped with a pneumatic opening system for internal cleaning. </p>
                                        
                                        <p>It is supplied with an electric control panel with a stainless steel cabinet. The processing cycle is completely automatic. </p>
                                        
                                        <p>The animal byproducts are loaded in the top of the machine by a lifter.</p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={S30} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Peeling Centrifuge</b></p>
                                        <p><i>Model D35-E, D40-E, D45-E</i></p>

                                        <p>These machines are suitable for peeling feet and heads of beef, pig, and sheep as well as entire piglets. </p>
                                        
                                        <p>The centrifuges are made entirely from AISI304 stainless steel.</p>

                                        <p>They are supplied with an electric control panel with a stainless steel cabinet.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={D40} alt="" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentCentrifuge;