import React, { Component } from 'react';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

import bnr from './../../images/banner/bnr2-4.jpg';
import brochureIcon from './../../images/brochure/almol-product-overview.jpg';
import ProductRangePDF from "./../../images/brochure/Almol-Range.pdf";

import bg3 from './../../images/background/bcr-2.jpg';

class OffalByproduct extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Offal &amp; Byproduct' midmenu="Products" activeMenu='Offal &amp; Byproduct' />
					</div>
                    <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                        <div className="section-full content-inner-2">
                            <div className="container background-white border-almol">
                                <div className="row">
                                   {/*  <div className="col-lg-6 col-md-8"> */}
                                        <div className=" col-lg-12">
                                            <h4 className="p-t20"><b>Pork &amp; Beef Offal</b></h4>
                                            <ul className="sun-list">
                                                <li>Pig Maws (stomachs)</li>
                                                <li>Pig Bung or Chitterling</li>
                                                <li>Pig Uteri</li>
                                                <li>Pig Kidney</li>
                                                <li>Pig Sweet Runners</li>
                                                <li>Pork Mucosa</li>
                                                <li>Pork Pancreas</li>
                                            </ul>
                                        </div>
                                        
                                   {/*  </div> */}
                                </div>
                            </div>
                        </div>
                        <div className="section-full add-to-call bg-primary p-tb30">
                            <div className="container">
                                <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                    <h2 className="m-b10 m-t10 text-white">Download Our Latest Brochure</h2>
                                    <div><a href={ProductRangePDF} download><img src={brochureIcon} alt="" /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}

export default OffalByproduct;