import React, {Component} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

/*  Pages  */
import Home from './pages/Home'
import AboutHistory from './pages/AboutHistory';
import AboutMission from './pages/AboutMission';
import CasingsNatural from './pages/CasingsNatural';
import CasingsHukki from './pages/CasingsHukki';
import CasingsSun from './pages/CasingsSun';
import Contact from './pages/Contact';
import Blog from './pages/BlogStandard';
import OffalByproduct from './pages/OffalByproduct';
import Equipment from './pages/Equipment';
import EquipmentHogBung from './pages/EquipmentHogBung';
import EquipmentCleaningScreen from './pages/EquipmentCleaningScreen';
import EquipmentConveyor from './pages/EquipmentConveyor';
import EquipmentCompactor from './pages/EquipmentCompactor';
import EquipmentAutoCasing from './pages/EquipmentAutoCasing';
import EquipmentCentrifuge from './pages/EquipmentCentrifuge';
import EquipmentOther from './pages/EquipmentOther';


import ScrollToTop from './element/ScrollToTop';

class Markup extends Component{
	render(){
		return(
			<BrowserRouter basename="/">
                <div className="page-wraper">
                    <Switch>
                        <Route path='/' exact component={Home} />
                        <Route path='/contact' exact component={Contact} />
                        <Route path='/history' exact component={AboutHistory} />
                        <Route path='/mission' exact component={AboutMission} />
                        <Route path='/natural-casings' exact component={CasingsNatural} />
                        <Route path='/hukki-casings' exact component={CasingsHukki} />
                        <Route path='/offal-byproduct' exact component={OffalByproduct} />
                        <Route path='/worldpac-sun-casings' exact component={CasingsSun} />
                        <Route path='/blog' exact component={Blog} />
                        <Route path='/equipment' exact component={Equipment} />
                        <Route path='/equipment-centrifuge' exact component={EquipmentCentrifuge} />
                        <Route path='/equipment-auto-casing' exact component={EquipmentAutoCasing} />
                        <Route path='/equipment-hog-bung' exact component={EquipmentHogBung} />
                        <Route path='/equipment-cleaning-screen' exact component={EquipmentCleaningScreen} />
                        <Route path='/equipment-conveyor' exact component={EquipmentConveyor} />
                        <Route path='/equipment-compactor' exact component={EquipmentCompactor} />
                        <Route path='/equipment-other' exact component={EquipmentOther} />
					</Switch>
                </div>
				<ScrollToTop />
            </BrowserRouter>	
		)
	}
	
}

export default Markup;