import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import bnr1 from './../../images/banner/bnr2-4.jpg';
import about11 from './../../images/about/pic11.jpg'; 
import bg3 from './../../images/background/bcr-2.jpg';

const imageBlog=[
	{image: about11},
];

class Blog extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
                    <div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr1 + ")" }}>
                        <PageTitle motherMenu='Almol News' midmenu="Media" activeMenu='Almol News' />
                    </div>
                    <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                        <div className="container max-w900">
								<BlogPost />
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

function BlogPost(){
	return(
		<>
			{imageBlog.map((item,index)=>(
				<div className="blog-post blog-lg background-white p-27 border-almol" key={index}>
					<div className="dlab-post-media dlab-img-effect zoom-slow">
						<Link to={"#"}><img src={item.image} alt="" /></Link> 
					</div>
					<div className="dlab-post-info">
						<div className="dlab-post-title ">
							<h4 className="post-title"><Link to={"#"}>Almol Officially Opens New High Tech Facility</Link></h4>
						</div>
						<div className="dlab-post-meta">
							<ul className="d-flex align-items-center">
								<li className="post-date"> <i className="fa fa-calendar"></i><strong>January</strong> <span> 2011</span> </li>
								<li className="post-author"><i className="fa fa-user"></i>By <Link to={"#"}>David</Link> </li>
							</ul>
						</div>
						<div className="dlab-post-text">
							<p>
								Almol has officially opened their new facility in Matraville. "We have completed the transition with all our NSW operations functioning from the new facility" said Managing Director David Moses.
							</p>
							<p>

								After years of planning, construction of the new facility commenced in 2009. “Our original premises at Botany served us well, but that building was no longer adequate for our increasing volume and expansion into new markets”.
							</p>
							<p>
								"We have also established new internal divisions which are focused on developing new products and technologies utilizing the high tech equipment we have installed. Construction was completed earlier this year and with the transition of staff and installation of new equipment, the facility is now fully operational”.
							</p>
						</div>
					</div>
				</div>
			))}	
		</>
	)
}
export {BlogPost};
export default Blog;