import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';

/*  Products */
import T26 from './../../images/equipment/compactors/T26.jpg';

class EquipmentCompactor extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Compactors' midmenu="Equipment" activeMenu='Compactors' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Compactor</b></p>
                                        <p><i>Model T26-50 & T30-90</i></p>
                                        
                                        <p>The compacting machine is specially designed to separate the solid-liquid components of sewage from beef, sheep and pig breeding (including sewage from animal stalls), as well as sewage from gallinaceous. It is easy to install and has minimal maintenance. </p>
                                        
                                        <p>The machine does not need any labour and it guarantees an optimal drying of the separated solid residual.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={T26} alt="" />
                                    </div>
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentCompactor;