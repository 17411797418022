import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

//Images
import LD100 from './../../images/equipment/other/LD100.jpg';
import MAS from './../../images/equipment/other/MAS.jpg';
import S200 from './../../images/equipment/other/S200.jpg';
import L200 from './../../images/equipment/other/200L.jpg';
import VC1250 from './../../images/equipment/other/VC1250.jpg';

import bnr from './../../images/banner/bnr2-7.jpg';
import bg3 from './../../images/background/bcr-2.jpg';


class EquipmentOther extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Other Equipment' midmenu="Equipment" activeMenu='Other' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Washing / Desalinating Machine</b></p>
                                        <p><i>Model LD100</i></p>

                                        <p>The gut washing-desalinating machine is completely built in AISI 316 – 304 stainless steel and suitable to wash, desalinate and dry the raw material, after it is loaded into the machine by a tilt lifter. </p>
                                            
                                        <p>This process can be executed simultaneously or according to separate phases. </p>
                                        
                                        <p>The processing time of each working phase is adjustable by means of a timer.</p> 
                                        
                                        <p>The speed and the rotation mode of the rotating drum can be customized.</p> 
                                        
                                        <p>The machine is also equipped with an automatic system for water recycling and salt recovery.</p>
                                        
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={LD100} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Stomach Opening Machine</b></p>
                                        <p><i>Model M.A.S</i></p>

                                        <p>The machine is made entirely of stainless steel. The stomachs are manually inserted into the machine where the stomachs are split and pre-washed. </p>

                                        <p>This machine includes a hopper to recover any waste material and an electric control panel with a stainless steel cabinet and low tension 24 V push-buttons. </p>
                                        
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={MAS} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Tilt Lifter for Standard 200/300 Kg Wagons</b></p> 
                                        <p><i>Model S200-S250</i></p>
                                        
                                        <p>Construction specifications:</p>
                                        <ul className="equip-list">
                                            <li>Column made of 6 mm thick AISI 304 stainless steel plate and shaped to hold the carriage housing.</li>
                                            <li>Carriage housing is made of special cast iron with chemically nickel-plated rust-proof coating; complete with 5 wheels that guarantee perfect long term slide-efficiency.</li>
                                            <li>The upper head is made of anticorodal aluminium with chemically nickel-plated rust-proof coating.</li>
                                            <li>Self-lubrificating lifting screw made of special steel.</li>
                                            <li>Mechanical fall-prevention system.</li>
                                            <li>Wagon fork made of 8 mm thick AISI 304 stainless steel tube and shaped 4 mm thick AISI 304 stainless steel plate.</li>
                                            <li>Base plate for fastening to the floor. </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={S200} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Cooking Vessels</b></p> 
                                        <p><i>Model Model 200L, 500L</i></p>
                                        
                                        <p>Suitable for cooking, blanching or defrosting by water bath. </p>
                                        
                                        <p>Made of AISI304 stainless steel insulated on the basin and on the lid, complete with steam system for cooking. </p>
                                        
                                        <p>The cooking water is heated by direct food grade steam. </p>
                                        
                                        <p>On request, they can be fitted with an electro pneumatic system to control the water temperature automatically.</p>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={L200} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Cooking Vessels</b></p> 
                                        <p><i>Model Model VC1250</i></p>
                                        
                                        <p>The unit is constructed in stainless steel AISI.304 with the chamber and lid built with a double wall, insulated with fibre glass and complete welded closed. </p>
                                        
                                        <p>The machine operates with direct vapour and has a total capacity of approximately 1.200 lt. </p>
                                        
                                        <p>The unit includes a perforated holding basket, constructed in stainless steel AISI.304 with an underside aperture, operated manually to unload the product and support brackets to hoist the unit. </p>
                                        
                                        <p>The machinery is complete of the following accessories:</p>

                                        <ul className="equip-list">
                                            <li>Pneumatic system with two pneumatic cylinders for opening and closing of the lid</li>
                                            <li>Vapour interception valve</li>
                                            <li>Draining Valve</li>
                                            <li>Cold Water Inlet Valve</li>
                                            <li>Vapour injector in stainless steel AISI.304,</li>
                                            <li>Electric control panel with a stainless steel cabinet and low tension 24 V push-buttons</li>

                                        </ul>
                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={VC1250} alt="" />
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentOther;