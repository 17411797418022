import React from "react";
import {Link} from 'react-router-dom';
import logo from './../../images/almol-logo.png';

const PageTitle = ({ motherMenu, midmenu, activeMenu }) => {
   return (
	   <div className="container almol-page-title">
			<div className="page-logo"><img src={logo} alt="" className="almol-page-logo" /></div>
			<div className="dlab-bnr-inr-entry">
				<h1 className="text-white">{motherMenu}</h1>

				<div className="breadcrumb-row">
					<ul className="list-inline">
						<li><Link to={"/"}>Home</Link></li>
						<li className="ml-1">{midmenu}</li>
						<li className="ml-1">{activeMenu}</li>
					</ul>
				</div>
			</div>
		</div>	
    );
};

export default PageTitle;
