import React, { Component } from 'react';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

/*  Product Images  */
import sun1 from './../../images/product/sun/sun_smoke_cas.jpg';
import sun2 from './../../images/product/sun/sun_caramel_casing.jpg';
import sun3 from './../../images/product/sun/sun_spice_cas_casing.jpg';
import sun4 from './../../images/product/sun/sun-spice-rs.jpg';
import sun5 from './../../images/product/sun/sun-spice-pt.jpg';
import sun6 from './../../images/product/sun/sun-color-cas.jpg';
import sun7 from './../../images/product/sun/sun-color-f.jpg';
import sun8 from './../../images/product/sun/sun-color-nw.jpg';
import sun9 from './../../images/product/sun/sun-f.jpg';
import sun10 from './../../images/product/sun/sun-magic.jpg';

import brochureIcon from './../../images/brochure/almol-product-overview.jpg';
import ProductRangePDF from "./../../images/brochure/Almol-Range.pdf";
import bg3 from './../../images/background/bcr-2.jpg';
import bnr from './../../images/banner/bnr2-7.jpg';

class CasingsSun extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='World Pac / Sun Casings' midmenu="Products" activeMenu='World Pac / Sun Casings' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="section-head text-black text-center">
                                    <h3 className="text-gray-dark m-b10 p-10 background-white">Almol is the exclusive Australian distributor of World Pac / Sun casings.</h3>
                                    <h2 className="box-title m-tb0"><span className="bg-primary"></span></h2>

                                </div>
                            </div>
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-12 text-center background-white">
                                        <p className="p-10">The range provides high end options to enhance meat and cheese products, by transferring colours and flavours, as well as decorative and functional coatings, during heat processing.</p>
                                    </div>
                                </div>
                                <div className="p-b10"></div>
                                <div className="col-lg-12 text-center background-white p-10">
                                    <h4 className="text-gray-dark">Here is a selection from the World Pac Range:</h4>
                                </div>
                            </div>
                            <div className="container p-t20 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Smoke CASSun Smoke CAS:</h6>
                                        <ul className="sun-list">
                                            <li>The high-barrier cook and ship casing impregnated with liquid smoke</li>
                                            <li>The taste and look of the good old days in a futuristic casing</li>
                                            <li>Applications include the production of meat products, cooked hams and processed cheese</li>
                                            <li>Always a reliably uniform smoke colour and flavour</li>
                                            <li>Allows production of smoked and unsmoked products together</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun1} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Caramel CAS:</h6>
                                        <ul className="sun-list">
                                            <li>The high barrier cook and ship casing impregnated with caramel</li>
                                            <li>Your solution to produce a caramel product without any difficulties</li>
                                            <li>For all cooked meat and poultry products and processed cheeses</li>
                                            <li>High productivity with easy, clean handling</li>
                                            <li>High shrink or non-shrink</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun2} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Spice FS:</h6>
                                        <ul className="sun-list">
                                            <li>The spice coated flat film or casing for cooked products and hams</li>
                                            <li>Gives your product a unique appearance</li>
                                            <li>Flat film, sheets and casing formats</li>
                                            <li>Very easy to handle and work with</li>
                                            <li>Spices stay in place through handling and slicing</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun3} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Spice CAS:</h6>
                                        <ul className="sun-list">
                                            <li>The high-barrier casing with spice coating</li>
                                            <li>This casing not only gives you spice coating, but also high-barrier features for longer shelf life and lower microbiological risks</li>
                                            <li>Great for cooked products, cooked hams and cooked ham products, poultry products, processed cheese</li>
                                            <li>Easy to handle and work with spice doesn´t fall off during handling and slicing production safety production flexibility due to long shelf life of the  products in the high-barrier casin </li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun3} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Spice RS:</h6>
                                        <ul className="sun-list">
                                            <li>The spice coated casing for semi-dry and dry sausages </li>
                                            <li>Sun Spice RS casings for an easy and safe production of spice coated semi-dry and dry sausages</li>
                                            <li>Great for dry sausage (salami) or semi-dry sausage</li>
                                            <li>Easy to handle and work with spice doesn´t fall off during handling and slicing production safety production flexibility due to long shelf life of the  products in the high-barrier casin </li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun4} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Spice PT:</h6>
                                        <ul className="sun-list">
                                            <li>The spice coated sheet for fish, cheese and American bacon</li>
                                            <li>Use our SUN SPICE PT to create unique specialities for the market place</li>
                                            <li>Great for salmon/fish, cheese, bacon</li>
                                            <li> easy to handle and work with higher productivity spice transfer in cold conditions no air leakers in vacuum pouches sheet can be precisely placed on the product</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun5} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Spice F NW:</h6>
                                        <ul className="sun-list">
                                            <li>The permeable color transfer casing</li>
                                            <li>The first smokeable casing with an edible color transfer print on the inner layer, which can be used on a double-clipping machine</li>
                                            <li>Great for cooked products cooked ham and cooked ham products poultry products dry products (salami) semi-dry products</li>
                                            <li>Can be used on a double clipping machine, also in combination with elastic netting easy to handle and work with, color doesn´t come off during handling and slicing due to presticking, no sticking needed after stuffing, no air bubbles, excellent peelability cuts down processing time by up to 50% because color comes from SUN COLOR smokeable the traditional way or with liquid smoke -only for smoke taste no soaking, which means less bacteriological risk</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            {/*  <img src={sun5} data-tilt alt="" /> */}
                                            &nbsp;
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Color CAS:</h6>
                                        <ul className="sun-list">
                                            <li>The high-barrier casing with color transfer </li>
                                            <li>The first high-barrier casing with an edible color printed on the inner layer, which transfers on the product during processing </li>
                                            <li>Great for cooked products, cooked hams and cooked ham products, poultry product</li>
                                            <li>Can be used on a double clipping machine, also in combination with elastic netting easy to handle and work with, color doesn´t come off during handling and slicing due to presticking, no sticking needed after stuffing, no air bubbles, excellent peelability cuts down processing time by up to 50% because color comes from SUN COLOR smokeable the traditional way or with liquid smoke -only for smoke taste no soaking, which means less bacteriological risk</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun6} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Color F:</h6>
                                        <ul className="sun-list">
                                            <li>The replacement of collagen, carageenan and cello flat films with color transfer on the inner layer</li>
                                            <li>Make your life easier with the smokeable, prestuck fibrous film with a special easy-peel coating and color transfer print on the inside</li>
                                            <li>Great cooked products cooked hams and cooked ham products poultry products also in combination with elastic netting</li>
                                            <li>Easy to handle and work with shorter process time, because smoking for coloring is not needed anymore - color comes from SUN COLOR smokeable the traditional way or with liquid smoke - only needed for smoke taste excellent 
                                    peeling higher productivity color doesn´t come off during handling and slicing</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun7} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Color NW:</h6>
                                        <ul className="sun-list">
                                            <li>The shaped color transfer casing</li>
                                            <li>Enjoy the benefits of the color transfer printed casing, sewn into a wide variety of shapes</li>
                                            <li>Great for cooked products, cooked ham and cooked ham products poultry products dry products (salami), semi-dry products</li>
                                            <li>Easy to handle and work with color doesn´t come off during handling and slicing production flexibility higher productivity saves smokehouse time, because color comes from 
                                    SUN COLOR smokeable the traditional way or with liquid smoke - only for smoke taste no soaking, which means less bacteriological risk</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun8} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Color TCF:</h6>
                                        <ul className="sun-list">
                                            <li>The flat film for smoked salmon</li>
                                            <li>Use the GRINDE-TECHNOLOGY and smoke your salmon without a smokehouse </li>
                                            <li>Great for salmon, fish - provides excellent texture, always uniform smoke color, always same smoke taste, quality assurance, reduced weight loss</li>
                                            <li>Easy to handle and work with no smokehouse time no smokehouse needed smoking without a smokehouse excellent peelability higher productivity</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            &nbsp;
                                            {/* <img src={sun} data-tilt alt="" /> */}
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Smoke F:</h6>
                                        <ul className="sun-list">
                                            <li>The smoke impregnated flat film</li>
                                            <li>Give your products a new look and taste with the world’s first smoke impregnated fibrous film with easy-peel coating</li>
                                            <li>Great for all cooked products, cold smoked products in combination with elastic netting use in molds, containers, vacuum pouches and thermoforming machines</li>
                                            <li>Easy to handle and work with due to presticking, no sticking after stuffing needed, no air bubbles no smokehouse time saving smokehouse capacity smoking without a smokehouse excellent peelability higher productivity</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            &nbsp;
                                            {/* <img src={sun} data-tilt alt="" /> */}
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun F:</h6>
                                        <ul className="sun-list">
                                            <li>The replacement of collagen, carageenan and cello flat films</li>
                                            <li>Never have headaches again! Use our SUN-F, a smokeable, prestuck fibrous film with special easy-peel coating</li>
                                            <li>Great for  cooked products cooked hams and cooked ham products poultry products also in combination with solid or elastic netting</li>
                                            <li>Easy to handle and work with smokeable the traditional way or with liquid smoke excellent peeling very high tear strength, even when wet due to presticking no sticking needed after stuffing, no air bubbles storage of flat film without refrigeration saves money - less damaged production higher productivity less expensive than collagen</li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun9} data-tilt alt="" />
                                        </div>
                                </div>
                            </div>
                            <br />
                            <div className="container p-t50 background-white border-almol p-b50">
                                <div className="row">
                                        <div className=" col-lg-8">
                                        <h6>Sun Magic:</h6>
                                        <ul className="sun-list">
                                            <li>The one and only pouch for microwave use with a self-venting steam 
                                    outlet at the seam for pasteurization or autoclave process application</li>
                                            <li>Great for ready-to-eat meals, soups, all types of convenience foods and snack products</li>
                                            <li>Less hot spot development, self venting occurs at temperatures over 100°C / 212°F easy to open</li>
                                            <li>Fill and seal by hand, fill by automatic bag packaging machines, fill by thermoforming machines, pasteurization or autoclave application possible  </li>
                                        </ul>
                                        </div>
                                        <div className="col-lg-4 col-md-4">
                                            <img src={sun10} data-tilt alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="p-b100 p-t100"><br /></div>
                            <div className="section-full add-to-call bg-primary p-tb30">
                                <div className="container">
                                    <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                        <h2 className="m-b10 m-t10 text-white">Download Our Latest Brochure</h2>
                                        <div><a href={ProductRangePDF} download><img src={brochureIcon} alt="" /></a></div>
                                        {/* <div><Link to={"#"} className="site-button button-md white"><img src={brochureIcon} alt="" /></Link></div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <Footer />
            </>
        )
    }
}

export default CasingsSun;