import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Header from './../layout/Header';
import Footer from './../layout/Footer';
import ProductCarousel from './../element/ProductCarousel';
import MainSlider from './../element/MainSlider';

import ProductRangePDF from "./../../images/brochure/Almol-Range.pdf";

/* Images */
import bg3 from './../../images/background/bcr-2.jpg';
import about11 from './../../images/about/pic11.jpg'; 
import snagIcon from '../../images/icons/sausage-85.png';
import qualIcon from '../../images/icons/quality-85.png';
import machineIcon from '../../images/icons/machine-85.png';
import brochureIcon from './../../images/brochure/almol-product-overview.jpg';

class Home extends Component {

    componentDidMount() {
       var i = 0;
		
		// Placeholder Animation Start
		var inputSelector = document.querySelectorAll('input, textarea');
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('focus', function(event) {
				return this.parentElement.parentElement.classList.add("focused");
			});
		}
		
		
		for (i = 0; i < inputSelector.length; i++) {
			inputSelector[i].addEventListener('blur', function(event) {
				var inputValue = this.value;	
				if ( inputValue === '' ) {
					this.parentElement.parentElement.classList.remove('filled');
					this.parentElement.parentElement.classList.remove('focused');  
				} else {
					this.parentElement.parentElement.classList.add('filled');
				}
			});
		}
    }

    render() {
        return (
            <>
                <Header />

                <div className="page-content bg-white rubik-font">
					<div className="owl-slider-banner main-slider">
						<MainSlider />
					</div>
                    <div className="content-block">
                        <div className="section-full industry-service" style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="row m-b80">
                                    <div className="col-lg-4 col-md-4">
                                        <div className="icon-bx-wraper ind-ser-bx">
                                            <div className="icon-flex">
                                                <div className="icon-lg m-b10">
                                                    <Link to={"#"} className="icon-cell text-primary">
                                                        <img src={machineIcon} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                                <div className="icon-content">
                                                    <h3 className="dlab-tilte text-center">Production Equipment</h3>
                                                    <p className="text-center">We specialize in consulting, design, and manufacture of machines for processing by-products and intestine operations for beef, pig, and sheep. {/* Our unique machines manufactured in Europe are designed to satisfy medium and large productions in compliance with the strictest regulations on safety, quality, noise and hygiene. */}</p>
                                                </div>
                                            <Link to={"/equipment"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="icon-bx-wraper ind-ser-bx active">
                                            <div className="icon-flex">
                                                <div className="icon-lg m-b10">
                                                    <Link to={"#"} className="icon-cell text-primary">
                                                        <img src={snagIcon} alt="" /></Link>
                                                </div>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte text-center">Passion for Casings</h3>
                                                <p className="text-center">At ALMOL, casings are our livelihood. It is our belief that there's no match for our service, personal attention to customers, or the quality of our product.</p>
                                            </div>
                                            <Link to={"/natural-casings"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-4">
                                        <div className="icon-bx-wraper ind-ser-bx">
                                        <div className="icon-flex">
                                                <div className="icon-lg m-b10">
                                                    <Link to={"#"} className="icon-cell text-primary">
                                                        <img src={qualIcon} alt="" />
                                                    </Link>
                                                </div>
                                            </div>
                                            <div className="icon-content">
                                                <h3 className="dlab-tilte text-center">Focus on Quality</h3>
                                                <p className="text-center">We operate under strict AQIS monitoring standards{/*  to export regulations */}. Our controls cover the handling, storage, distribution, and hygiene under HACCP framework.</p>
                                            </div>
                                            <Link to={"/mission"} className="site-button btn-block d-flex justify-content-between"><span>Read More</span> <i className="ti-arrow-right"></i></Link>
                                        </div>
                                    </div>
                                </div>
                                <div className="row d-flex align-items-center background-white">
                                    <div className="col-lg-5 col-md-12 m-b30">
                                        <h2 className="box-title m-t0 m-b20 font-40"><span className="font-weight-400">About </span><br />ALMOL</h2>
                                        <p>Almol is a 100% Australian owned and run company and is one of Australia’s leading producers and suppliers specialising in natural casings. </p>
                                        <p className="m-b20 p-b20">Established in 1969 with its original head office in Botany NSW, Almol has grown to become a market leader in the field of casings and a well-respected member of the meat industry.</p>
                                        <Link to={"/history"} className="site-button button-md">Read More</Link>
                                    </div>
                                    <div className="col-lg-7 col-md-12">
                                        <img src={about11} className="radius-sm" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full bg-blue-light content-inner explore-projects" style={{ backgroundImage: "url(" + bg3 + ")" }}>
							<div className="container">
                                <div className="section-content">
                                    <div className="row">
                                        <div className="col-md-12 col-lg-12 section-head text-center">
                                            <h2 className="m-b0 font-40"><span className="font-weight-400">Explore</span> Our Products</h2>
                                            <p className="m-b0">Highest quality natural and synthetic sausage casings.</p>
                                        </div>
                                    </div>
                                    <div className="">
										<ProductCarousel /> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-full add-to-call bg-primary p-tb30">
                            <div className="container">
                                <div className="d-lg-flex d-sm-block justify-content-between align-items-center">
                                    <h2 className="m-b10 m-t10 text-white">Download Our Latest Brochure</h2>
                                    <div><a href={ProductRangePDF} download><img src={brochureIcon} alt="" /></a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}
export default Home;