import React, {Component} from 'react';
//import {Link} from 'react-router-dom';
import Slider from "react-slick";

import bg25 from './../../images/background/bg25.jpg';
import logo from './../../images/almol-logo.png';


function SampleNextArrow(props) {
  const { onClick } = props;
  return (
  	<div className="owl-nav">
		<div className="owl-next la la-angle-right"  onClick={onClick}/>
	</div>	
  );
}

function SamplePrevArrow(props) {
  const { onClick } = props;
  return (
	<div className="owl-nav">
		<div className=" owl-prev la la-angle-left" onClick={onClick} style={{zIndex:1 }}/>
	</div>
  );
}

class Index5Slider extends Component{
	
	render(){
		var settings = {		
			arrows: true,
			dots: false,
            slidesToShow: 1,
			fade: true,	
            infinite: true,
			autoplay: true,
			nextArrow: <SampleNextArrow />,
			prevArrow: <SamplePrevArrow />,
			responsive: [
				{
				  breakpoint: 1200,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 991,
				  settings: {
					slidesToShow: 1,
					
				  }
				},
				{
				  breakpoint: 576,
				  settings: {
					slidesToShow: 1,
					
				  }
				}
			]
        };
		return(
			
			<Slider className="owl-slider owl-carousel owl-theme owl-none " {...settings}>
				<div className="item slide-item">
					<div className="slide-item-img"><img src={bg25} className="w-100" alt=""/></div>
					<div className="slide-content ">
						<div className="slide-content-box container align-center">
							<img src={logo} alt="" width="200px" className="slider-logo" />
							<div className="text-center">
								<h1 className="text-almol font-weight-700">ALMOL CASINGS</h1>
								<h4 className="text-blue text-border-blue">Adding Value Throughout the Australian & International Meat Industry For Over 50 Years</h4>
							</div>
						</div>
					</div>
				</div>
			</Slider>	
		)
		
	}
	
}

export default Index5Slider;