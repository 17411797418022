import React, { Component } from 'react';

import Header from './../layout/Header';
import Footer from './../layout/Footer';
import PageTitle from './../layout/PageTitle';

/*  Equipment Images */
import SR10 from './../../images/equipment/cleaning/SR10.jpg';
import SR20 from './../../images/equipment/cleaning/SR20.jpg';

/*  Layout Images */
import bnr from './../../images/banner/bnr2-4.jpg';
import bg3 from './../../images/background/bcr-2.jpg';


class EquipmentCleaningScreen extends Component {
    render() {
        return (
            <>
                <Header />
                <div className="page-content bg-white">
					<div className="dlab-bnr-inr" style={{ backgroundImage: "url(" + bnr + ")" }}>
						<PageTitle motherMenu='Cleaning Screens' midmenu="Equipment" activeMenu='Cleaning Screens' />
					</div>
                    <div className="content-block">
                        <div className="section-full content-inner-2"  style={{ backgroundImage: "url(" + bg3 + ")" }}>
                            <div className="container">
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Cleaning Screens</b></p>
                                        <p><i>Micaf with Standard Fixed Scraper Drum- Model SR10</i></p>
                                        
                                        <p>The cleaning screen is a machine specifically designed for the solid-liquid separation of sewage. It is widely used in all systems that require high-capacity separators. This machine is easily installed and it does not require any labour for its operation. </p>

                                        <p>Maintenance and energy consumption are reduced to the minimum. </p>

                                        <p>This machine can be used for various applications which include the food and livestock industry, wine industry, tanneries and paper mills. Within the civil field, it can be used for water and mud treatment.</p>
                                        
                                        <p>The machine does not need any labour and it guarantees an optimal drying of the separated solid residual.</p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={SR10} alt="" />
                                    </div>
                                </div>
                                <br />
                                <div className="row background-white border-almol">
                                    <div className="col-lg-6 equip-text">
                                        <p><b>Cleaning Screens</b></p>
                                        <p><i>Micaf with Mobile Scraper Drum - Model SR20</i></p>

                                        <p>The cleaning screen with the mobile blade is a machine specifically designed to screen particularly thick sewage, or sewage with suspended particles that could obstruct the filtering drum of a conventional cleaning screen. </p>
                                        
                                        <p>The particular movement of the scraping blade, tangent to the filtering drum and the high-pressure interior washing, continuously clean the machine and therefore improve its performance. </p>

                                        <p>It can be used in many different fields, such as food, fruit and vegetable processing and meat and fish processing industries as well as slaughterhouses. It can also be used in other sectors such as tanneries, paper mills and wine industries.</p>

                                    </div>
                                    <div className="col-lg-6 equip-img">
                                        <img src={SR20} alt="" />
                                    </div>
                                </div>
                               
                            </div>
                            
                        </div>
                    </div>
                </div>

                <Footer />
            </>
        )
    }
}

export default EquipmentCleaningScreen;